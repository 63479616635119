import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/humberlogo.svg";
import "./navbar.styles.css";
import humberlogo from '../../assets/Humberone.png'

const NavbarComponent = () => {
  useEffect(() => {
    const userType = navigator.userAgent
    const nav = document.querySelector('.navbar')
    window.addEventListener('scroll', function($event){
      if($event.path[1].scrollY > 245){
        if(userType.includes('Windows') || userType.includes('Mac')){
          nav.classList.add('sticky')
        }else{
          nav.classList.add('sticky-md')
        }
      }else{
        nav.classList.remove('sticky')
          nav.classList.add('fixed-top')
          nav.classList.remove('sticky-md')
      }
        
    })
  }, []);
  return (
    <Navbar   expand="lg" className="fixed-top">
      
      <Navbar.Brand href="#home">
        {/* <Logo className="logo" /> */}
        <img src={humberlogo} className="humberlogo-nav" alt="humberbone logo"/>
      </Navbar.Brand >
       <Navbar.Toggle aria-controls="basic-navbar-nav" /> 
       
      

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#services">Our Services</Nav.Link> 
          <div className = "vertical"></div>

          <Nav.Link href="#contact">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
