import React from "react";
import NavBar from "../navbar/navbar.component";
import { Container } from "react-bootstrap";
import background from "../../assets/heroimage.png";
import boximage from "../../assets/humberoneboxlogo.png";
import Services from "../services/services.component";

import "./homepage.styles.css";

import { IntroSection } from "../section1/section1.component";
import Contact from "../contact/contact.component";
import GetIntouch from "../getintouch/getinto.component";
import Client from "../clients/clients.component";
import Process from "../process/process.component";
import Hero from "../herosection/herosection.component";
import  ChooseUs from "../whyChooseUs/index"

const HomePage = ({ H1 }) => {
  const array = [...Array(4).keys()];
  return (
    <div className="wrapper">
      <Hero background={background} boximage={boximage} H1={H1} />
      <Services H1={H1} />
      <IntroSection H1={H1} />
      <ChooseUs H1={H1} />
      <Process H1={H1} />
      <GetIntouch H1={H1} />
      <Contact H1={H1} />
      <Client H1={H1} />
    </div>
  );
};

export default HomePage;
