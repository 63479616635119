import React from "react";
import "./header.styles.css";

const headerText = ({fontSize, display, children, align, color, margin_0, ...rest }) => {
  return (
    <div className="mb-3">
      <h1 className="h1-element"
        {...rest}
        style={{
          textAlign: align ? align : "center",
          color: color ? color : null,
          fontSize:fontSize
        }}
      >
        {children}
      </h1>
      <hr className={`${margin_0 ? "h-line-margin" : ""} h-line ${display ? " display-none" :""}`} />
    </div>
  );
};

export default headerText;
