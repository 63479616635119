import React, { useEffect } from "react";
import anime from "animejs";
import  humbertech from '../../assets/spinnerCircle/tech.png';
import  humbervas from '../../assets/spinnerCircle/vaswhite.png';
import  humb from '../../assets/spinnerCircle/hubbbb.png';
import HumberLogo from '../../assets/Humberlogo.png'
import "./logo.styles.css";


const LogoSpinnerMD = () => {
  useEffect(() => {
    const path = anime.path("#svg-md #path1");
    const path2 = anime.path("#svg-md #path2");
    var total_time = 6000;
    var opacity_time = 2500;
    anime({
        targets: "#icon1",
        translateX: path("x"),
        translateY: path("y"),
        easing: "linear",
        duration: total_time,
        loop: true,
      });
  
      anime({
        targets: "#icon1",
        opacity: [0, 1],
        easing: "linear",
        duration: opacity_time,
      });

      function anchorAnimate(){
        anime({
            targets: "#icon2",
            translateX: path2("x"),
            translateY: path2("y"),
            easing: "linear",
            duration: total_time,
            loop: true,
          });
      
          anime({
            targets: "#icon2",
            opacity: [0, 1],
            easing: "linear",
            duration: opacity_time,
          });
      }

      let anchorItch = anime({
        targets: "#icon3",
        translateX: path2("x"),
        translateY: path2("y"),
        easing: "linear",
        duration: total_time,
        loop: true,
      });
     
    setTimeout(anchorAnimate, (total_time / 3) * 2);
      anchorItch.reverse()
  },[]);
  return (
    <div className="demo-md">
      
      
      <img id="icon1" src={humbertech} alt="icon" style={{
        height:'60px'
      }} />
      <img id="icon2" src={humbervas} alt="icon" style={{
        height:'60px'
      }} />
      <img id="icon3" src={humb} alt="icon" style={{
        height:'60px'
      }} />
      <img className="humberLogo-md" src={HumberLogo} alt="humber logo"/>
      <svg
      id="svg-md"
        width="400"
        height="400"
        viewBox="0 0 275 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
        id="path1"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="white"
          d="M137.5 263C213.439 263 275 204.125 275 131.5C275 58.8745 213.439 0 137.5 0C61.5609 0 0 58.8745 0 131.5C0 204.125 61.5609 263 137.5 263ZM137.5 260C210.678 260 271.5 202.469 271.5 131.5C271.5 60.5314 210.678 3 137.5 3C64.3223 3 4 60.5314 4 131.5C4 202.469 64.3223 260 137.5 260Z"
          
        />
        <path 
            id="path2"
            fill="white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138 218C188.258 218 229 179.049 229 131C229 82.9512 188.258 44 138 44C87.7421 44 47 82.9512 47 131C47 179.049 87.7421 218 138 218ZM138 216.015C186.43 216.015 226.684 177.953 226.684 131C226.684 84.0474 186.43 45.9848 138 45.9848C89.5697 45.9848 49.6473 84.0474 49.6473 131C49.6473 177.953 89.5697 216.015 138 216.015Z"
         
        />
      </svg>
    </div>
  );
};

export default LogoSpinnerMD


