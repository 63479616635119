import React from "react";
import { Card, Button } from "react-bootstrap";
import "./service.style.css";

const Service = ({ id, imageUrl, color, title, subtitle, duration ,activity, setOpen, setModalContent}) => {
//  const activityArray = activity.split(',')
// onClick={() => setOpen(o => !o)
 
  return (
    <div id="services"
      data-aos="fade-right"
      data-aos-duration={duration}
      className=" service-div"
      style={{width: 300, margin: 10}}
    >
      <Card>
        <Card.Img variant="top" src={`${imageUrl}`} />
        <Card.Body>
          {activity?.map((res, i)=>{
              return(
                <p key={i}>
                {res.url ? <a className="card-link d-flex justify-content-center" href={res.url} target="_blank" alt="img">
                  {res.text}
                </a>
                :
                <a className="card-link d-flex justify-content-center" style={{color: "#fff", cursor: "pointer"}} onClick={() => {
                  setOpen(prev => !prev)
                  setModalContent({title: res.text,
                  description: res.description
                  })

                } }>{res.text}</a>}
              </p>
              )
            })
          }
        </Card.Body>
        <Card.Footer
          style={{
            backgroundColor: `${color}`,
            color: "white",
            height: "40%",
          }}
        >
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle> {subtitle}</Card.Subtitle>
        </Card.Footer>
        
      </Card>
   
    </div>
  );
};

export default Service;
