import React, {useState} from "react";
import { Container } from "react-bootstrap";
import Service from "../service/service.component";
import SERVICE_DATA from "./service.data";
import "bootstrap/dist/css/bootstrap.min.css";
import './services.styles.css'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';


const Services = ({H1})=>{
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState(false)
  const handleClose = () => {
    setOpen((prev) => !prev)
  }
  return (
    <section className="sec-services">
      <div className="container-fluid">
        <div>
          <H1 data-aos="zoom-in" >
            Our Services
          </H1> 
          <div className="">
        
              <div className="services_card">
                {SERVICE_DATA.map(
                  ({ id, title, subtitle, color, imageUrl, duration, activity }) => (

                    <Service
                    setOpen={setOpen}
                    setModalContent={setModalContent}
                      key={id}
                      title={title}
                      subtitle={subtitle}
                      color={color}
                      imageUrl={imageUrl}
                      duration={duration}
                      activity={activity}
                    />
                  )
                )}
              </div>
        
          </div>
        </div>
      </div>

   
);
    <Dialog onClose={handleClose} open={open}>
      <div style={{padding: 10,
    width: 500,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"}}> 
      <h4 style={{textAlign: "center", fontWeight: "bold"}}>{modalContent.title}</h4>
          <p style={{textAlign: "center", }}>{modalContent.description}</p>
      </div>
    </Dialog>
    </section>
  );
}



export default Services;
