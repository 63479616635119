import React from "react";
import { ReactComponent as Logo } from "../../assets/humberlogo.svg";
import "./footer.styles.css";


const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row mb-3 d-flex footer-top-row justify-content-between">
          <div className="logo-md">
            <Logo />
          </div>
          <div>
            <div className="row">
              <div className="col-md-6 box footer-md-hidden">
                <h3>Legal</h3>

                <p>Cookies Policy</p>
                <p>Privacy Policy</p>
              </div>
              <div className="col-md-6">
                <h3 className="footer-md-hidden">Connect</h3>
                <div className="row mb-3 md-margin">
                  <div className="col-md-3 col-md-3 col-sm-3 social_wrapper ">
                    <i className="fab fa-facebook "></i>
                  </div>
                  <div className="col-md-3 col-md-3 col-sm-3 col-md-3 social_wrapper">
                    <i className="fab fa-instagram"></i>
                  </div>
                  <div className="col-md-3 social_wrapper col-md-3 col-sm-3 ">
                    <i className="fab fa-linkedin"></i>
                  </div>
                  <div className="col-md-3 col-md-3 col-sm-3 social_wrapper">
                    <i className="fab fa-twitter-square"></i>
                  </div>
                </div>
                <a  className="humber_email"> info@humberone.com</a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <h5>This website uses cookies</h5>
            <br />
            <p>
            Delivering 100% customer satisfaction and quality work without compromise, since 1982.
            </p>
            <br />
            <h6> &copy; &nbsp;HumberOne {year}, all rights reserved</h6>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
