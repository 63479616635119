import React from "react";
import { Button, Container } from "react-bootstrap";
import bgWoman from "../../assets/womanBg.png";
import "./getintouch.styles.css";

const GetIntouch = ({ H1 }) => {
  return (
    <section>
      <div
        className="get_intouch jumbotron-fluid mb-0"
        style={{
          backgroundImage: `url(${bgWoman})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "700px",
          
        }}
      >
        <Container>
          <div className="section-abound">
            <div className="row">
              <div className="left-align col-sm-12 col-xs-12">
                <H1 
                  align="left"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  color="white"
                  margin_0
                >
                  No boundaries to who we work with
                </H1>
                <p
                  className="section-abound-p  text-left"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  We offer services to both private and public enterprises. Our
                  clients spring from different business types such as
                  micro-businesses, SMEs, Startups, and large corporations.
                  <br />
                  <br /> Humber has a global reach and seasoned relationships
                  with peers and institutions all around the world.
                </p>
                <br />
                {/* <Button data-aos="fade-up" data-aos-duration="500">
                  <i
                    style={{
                      transform:`rotate(
                      45deg
                      )`,
                    }}
                    className="fas fa-arrow-up"
                  ></i>{" "}
                  Get in touch
                </Button> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default GetIntouch;
