import React, { useEffect, useState } from "react";
import AOS from "aos";

import "./App.css";
import NavBar from "./components/navbar/navbar.component";
import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "./components/footer/footer.component";
import Home from "./components/homepage/homepage.component";
import "aos/dist/aos.css";
import H1 from "./components/headerText/header.component";
import Spinner from "./components/spinner/spinner.component";
import anime from "animejs";
import LogSpine from './components/logoSpinner/logoSpinner.component'

function App() {
  const [Spin, SetSpin] = useState(true);
  useEffect(() => {
    AOS.init({});
    setTimeout(() => {
      SetSpin(false);
    }, 3000);
  }, []);
  return (
 

    <div>
       {Spin ? (
         <Spinner />
       ) : (
         <div>
           {" "}
         <NavBar />
           <Home H1={H1} />
           <Footer />
         </div>
       )}
     </div>
  );
}

export default App;
