import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import "./contact.styles.css";
import { Form } from "./contact.styles.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [userCredentails, setCredentails] = useState({
    email: null,
    subject: null,
    message: null,
  });

  const [validation, setValidation] = useState({});

  const [message, setMessage] = useState({
    success: null,
    error: null,
  });
  const [showSpin, setShowSpin] = useState(false);
  const handleChange = ($event) => {
    const { value, name } = $event.target;
    setCredentails({ ...userCredentails, [name]: value });
    console.log(userCredentails);
  };
  const notify = (message) => {
    toast(message);
  };

  const handleValidation = () => {
    let isformValid = true;
    let errors = {};
    if (!userCredentails["email"] === null) {
      isformValid = false;
      errors["email"] = "Email cannot be empty";
    }

    if (!userCredentails["subject"] === null) {
      isformValid = false;
      errors["subject"] = "Subject cannot be empty";
    }
    if (!userCredentails["message"] === null) {
      isformValid = false;
      errors["message"] = "Message cannot be empty";
    }

    // if (typeof userCredentails["email"] !== undefined) {
    //   let lastAtPos = userCredentails["email"].lastIndexOf("@");
    //   let lastDotPos = userCredentails["email"].lastIndexOf(".");

    //   if (
    //     !(
    //       lastAtPos < lastDotPos &&
    //       lastAtPos > 0 &&
    //       userCredentails["email"].indexOf("@@") == -1 &&
    //       lastDotPos > 2 &&
    //       userCredentails["email"].length - lastDotPos > 2
    //     )
    //   ) {
    //     isformValid = false;
    //     errors["email"] = "Email is not valid";
    //   }
    // }

    setValidation(errors);
    return isformValid;
  };

  let formatPhoneNumber = (str) => {
    // return str
    //Filter only numbers from the input
    return str.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };


  const handleSubmit = (e) => {
    if(handleValidation()){
    
    console.log(validation)
    console.log()
    setShowSpin(true);
    e.preventDefault();
    console.log(userCredentails);
    const subject = "Contact us";
    const { email, message } = userCredentails;
    const URL = `http://45.153.185.209:8081/api/email/humberonecontact`;
    //const URL = `http://45.153.185.209:8081/sendcomposemail?email=${email}&subject=${subject}&message=${message}`;
    const axiosInstance = axios.create({
      timeout: 20000,
    });

    axios.post(URL, userCredentails)
      .then((res) => {
        setShowSpin(false);
        notify("Message send successfull");
        console.log(res);
      })
      .catch((err) => {
        setShowSpin(false);
        toast.error(
          `${
            err.response === undefined
              ? "Cannot send request at the momment or Time out reached"
              : err.response.data.error
          } !!`
        );
        console.log(err.response);
      });
    }else{
        alert('you need to fill  all the field')
    }
  };

  return (
    <section
      style={{
        background: "#f8f8f8",
      }}
      className="contact"
      id="contact"
    >
      <ToastContainer />
      <Container>
        <div className="form">
          <div className="contact-info-form">
            <span className="circle one"></span>
            <span className="circle two"></span>
            <form onSubmit={handleSubmit}>
              <h3 className="title mb-4 mt-4">Contact us</h3>
              <div className="social-input-containers">
                <input required
                  onChange={handleChange}
                  type="text"
                  name="message"
                  className="input"
                  placeholder="Message"
                />
                <span style={{color: "red"}}></span>
              </div>
              <div className="social-input-containers">
                <input required
                  onChange={handleChange}
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Email"
                />{" "}
              </div>
              <div className="social-input-containers select_box">
                <select required
                  name="subject"
                  className="input "
                  id="sel1"
                  onChange={handleChange}
                >
                  <option value="">Select a service</option>
                  <option value="humbervas">Humber Vas</option>
                  <option value="humbergames">Humber Games</option>
                  <option value="humbertech">Humber Tech</option>
                </select>
              </div>

              <button
                type="submit"
                disabled={showSpin}
                className="btn submit-contact"
              >
                Submit &nbsp;{" "}
                {showSpin ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
              </button>
            </form>
          </div>
          <div className="contact-info">
            <h3 className="title mb-4 mt-4">Contact information</h3>

            <div className="row">
              <div className="col-md-12 col-sm-6 col-xs-6 info-social">
                <p>
                 
                  <i className="fas fa-mobile-alt"></i> &nbsp;&nbsp;
                  
                  {
                    formatPhoneNumber('+2347032463038')
                  }
                </p>
               
              </div>
              <div className="col-md-12 col-sm-6 col-xs-6 info-social">
                <p>
                  {" "}
                  <i className="far fa-envelope"></i>{" "}
                  &nbsp;&nbsp;info@humberone.com
                </p>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 info-social">
                <div className="d-flex justify-content-center ">
                  <i className="fas fa-map-marker-alt"></i>
                  <p className="address">
                    24 Ekololu Street, Surulere, Nigeria
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-absolute">{/* <img /> */}</div>

            {/* <div className="info">
              <div className="social-information">
                <i className="fa fa-mobile-phone"></i>
                <p>+234 706 055 5061 </p>
              </div>
              <div className="social-information">
                <i className="fa fa-envelope-o"></i>
                <p>info@humberone.com</p>
              </div>
              <div className="social-information">
                <i className="fa fa-map-marker"></i>
                <p>
                  24 Ekololu Street,
                  <br />
                  Surulere,
                  <br />
                  Nigeria
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
