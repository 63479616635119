import React from 'react'
import { ChooseUseData } from './chooseus.data'
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css";


function Index({H1}) {
  return (
    <section className="choose">
         <h1 data-aos="zoom-in" style={{textAlign: "center"}} >
             Why Choose Us
          </h1> 
        <div> 
            {ChooseUseData.map(data => (
                <div className="card"> 
                    <img className="choose_img" src={data.image} alt="" />
                    <div className="content_choose">
                    <h4>{data.title}</h4>
                    <p>{data.description}</p>
                    </div>
                </div>
            ))}

        </div>
    </section>
  )
}

export default Index
