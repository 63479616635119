import React, { useState, useEffect } from "react";
import anime from "animejs";
import HumberLogo from "../../assets/Humberlogo.png";

import "./logo.styles.css";
import humbergame from "../../assets/spinnerCircle/Gmaes.png";
import humbertech from "../../assets/spinnerCircle/tech.png";
import humbervas from "../../assets/spinnerCircle/vas.png";
import humb from "../../assets/spinnerCircle/hubbbb.png";

const LogoSpinner2 = () => {
  useEffect(() => {
    const path = anime.path("#demo-svg #path9");
    const path2 = anime.path("#demo-svg #path10");
    var total_time = 6000;
    var opacity_time = 2500;

    // anime({
    //   targets: "#emoji1",
    //   translateX: path("x"),
    //   translateY: path("y"),
    //   easing: "linear",
    //   duration: total_time,
    //   loop: true,
    // });

    // anime({
    //   targets: "#emoji1",
    //   opacity: [0, 1],
    //   easing: "linear",
    //   duration: opacity_time,
    // });

    function carAnimate() {
      anime({
        targets: "#emoji2",
        translateX: path("x"),
        translateY: path("y"),
        easing: "linear",
        duration: 14000,
        loop: true,
      });
      anime({
        targets: "#emoji2",
        opacity: [0, 1],
        easing: "linear",
        duration: opacity_time,
      });
    }

    function anchorAnimate() {
      anime({
        targets: "#emoji3",
        translateX: path("x"),
        translateY: path("y"),
        easing: "linear",
        duration: 14000,
        loop: true,
      });
      anime({
        targets: "#emoji3",
        opacity: [0, 1],
        easing: "linear",
        duration: opacity_time,
      });
    }

    const humberHub = () => {
      const reverse = anime({
        targets: "#emoji5",
        translateX: path2("x"),
        translateY: path2("y"),
        easing: "linear",
        duration:14000,
        // duration: total_time,
        loop: true,
      });
      reverse.reverse();

      anime({
        targets: "#emoji5",
        opacity: [0, 1],
        easing: "linear",
        duration: opacity_time,
      });
    };
    // humberHub();

    let anchorItch = anime({
      targets: "#emoji4",
      translateX: path2("x"),
      translateY: path2("y"),
      easing: "linear",
      duration: 14000,
      loop: true,
    });

    // anchorItch.tick(950000);
    setTimeout(humberHub, 1000)
    setTimeout(carAnimate, 1000);
    setTimeout(anchorAnimate, (total_time / 3) * 2);
    setTimeout(() => {
      anchorItch.reverse();
    }, total_time / 3);
  },[]);

  return (
    <div className="demo-wrapper">
      <img
        id="emoji5"
        src={humb}
        alt="icon"
        style={{
          height: "60px",
        }}
      />
      <img
        id="emoji4"
        src={humbervas}
        alt="icon"
        style={{
          height: "60px",
        }}
      />
      <img
        id="emoji2"
        src={humbergame}
        alt="icon"
        style={{
          height: "60px",
        }}
      />
      <img
        id="emoji3"
        src={humbertech}
        alt="icon"
        style={{
          height: "60px",
        }}
      />
      <img className="humberLogo" src={HumberLogo} alt="humber logo" />

      <svg
        width="475"
        height="463"
        id="demo-svg"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4667 4433"
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />

          <path
            stroke="#ffff"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
            id="path9"
            d="M2333 0c1289,0 2334,992 2334,2216 0,1224 -1045,2217 -2334,2217 -1288,0 -2333,-993 -2333,-2217 0,-1224 1045,-2216 2333,-2216zm0 34c1270,0 2298,977 2298,2182 0,1206 -1028,2183 -2298,2183 -1269,0 -2298,-977 -2298,-2183 0,-1205 1029,-2182 2298,-2182z"
          />
          <path
            stroke="#ffff"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
            id="path10"
            d="M2333 917c756,0 1369,582 1369,1299 0,718 -613,1300 -1369,1300 -755,0 -1368,-582 -1368,-1300 0,-717 613,-1299 1368,-1299zm0 20c745,0 1348,573 1348,1279 0,707 -603,1280 -1348,1280 -744,0 -1347,-573 -1347,-1280 0,-706 603,-1279 1347,-1279z"
          />
        </g>
      </svg>
     
    </div>
  );
};

export default LogoSpinner2;
