import React, { useState, useCallback } from "react";
import { Container, Card, Button } from "react-bootstrap";
import "./section1.styles.css";
//import Proccess from "../processes/processess.component";
import { data } from "../process/index";

export const H1 = ({ children }) => {
  return (
    <div>
      <h1 className="text-center">{children}</h1>
    </div>
  );
};

export const IntroSection = () => {
  return (
    <section className="intro-section">
      <div className="container-fluid">
        {/* <section>
        <div className="  jumbotron-fluid mb-0">
          <Container>
            <div className="d-flex justify-content-between">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <blockquote className=" text-left blockquote inovation-1">
                    &quot;An appetite for the unusual and challenging; the
                    knowledge and skill to innovate; the power to execute
                    &quot;
                  </blockquote>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <p className="inovation-1">
                    &quot; At Humber, we look at each assignment as a unique
                    situation and an opportunity to leverage business acumen
                    with many years experience and the expertise of our diverse
                    and dynamic staff. We deliver the highest quality work on
                    each of our products and services to ensure clients get
                    maximum satisfaction. Humber undertakes projects with a
                    focused, professional approach anzd provides objectivity and
                    a clear understanding of the needs and expectations of our
                    clients. We believe that trust is the foundation of success.
                    Our professionals work zealously on behalf of clients to
                    ensure that the confidence placed in us is respected and
                    constantly reinforced. Using a hands-on approach, Humber
                    undertakes all work in a cost effective manner and
                    implements tailor-made solutions.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section> */}
        <div>
          <Container>
            <div className="row" style={{ padding: "auto" }}>
              <div className="col-md-6 col-sm-12 col-xs-12 ">
                <h3
                  className="inovation-1 h3"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                >
                  "An appetite for the unusual and challenging; the knowledge
                  and skill to innovate; the power to execute"
                </h3>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-12">
                <p
                  className="inovation-1"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-offset="0"
                >
                  At Humber, we look at each assignment as a unique situation
                  and an opportunity to leverage business acumen with many years
                  experience and the expertise of our diverse and dynamic staff.
                  We deliver the highest quality work on each of our products
                  and services to ensure clients get maximum satisfaction.<br/><br/>
                  Humber undertakes projects with a focused, professional
                  approach anzd provides objectivity and a clear understanding
                  of the needs and expectations of our clients. We believe that
                  trust is the foundation of success. Our professionals work
                  zealously on behalf of clients to ensure that the confidence
                  placed in us is respected and constantly reinforced. Using a
                  hands-on approach, Humber undertakes all work in a cost
                  effective manner and implements tailor-made solutions.
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};
