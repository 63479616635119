import w1 from "../../assets/workprocess/w1.png";
import w2 from "../../assets/workprocess/w2.png";
import w3 from "../../assets/workprocess/w3.png";
import w4 from "../../assets/workprocess/w4.png";

export const data = [
  {
    id: 1,
    image: w1,
    title: "Ideate",
  },
  {
    id: 2,
    image: w4,
    title: "Plan",
  },

  {
    id: 3,
    image: w3,
    title: "Implement",
  },
  {
    id: 4,
    image: w2,
    title: "Produce",
  },
];
