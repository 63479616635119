


import React, { Component} from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CLIENT_IMAGE from './client.js'
import H1 from '../headerText/header.component'
import './clients.styles.css'




 class Client extends Component{
     constructor(){
         super();

         
     }
    
    render() {
       
        var settings = {
          dots: true,
          infinite: true,
          speed: 2000,
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay:true,
          autoplaySpeed:1000,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            }
          ]
        };
        return (
        <section className="slider-wrapper">
          <div>
            
            <H1 data-aos="zoom-in"  align="center">Clients we worked for</H1>
            
            <Slider {...settings}>
              {
                  CLIENT_IMAGE.map(({image}, i) =>(
                    <div className="d-flex justify-content-center" key={i} >
                      
                    <img src={image} alt="logo" />
                  </div>
                  ))
              }
 
            </Slider>
          </div>
          </section>
        );
      }


    

}

export default Client