import React, { useState, useEffect } from "react";
import Card from "../card/card.component";
import { data } from "./index";
import "./process.styles.css";
import anime from "animejs";


const Process = ({H1}) => {
  const [newData] = useState(data);
  useEffect(() => {
    const eli = document.querySelector(".process");
    const elements = document.querySelectorAll(".process-cols");
   

    // const observer = new IntersectionObserver((entries) => {
    //     elements.forEach((node)=>{
          
    //     })
    //     if(entries[0].intersectionRatio > 0 && entries[0].isIntersecting === true){
    //       elements.forEach((node)=>{
    //         anime({
    //           targets: node,
    //           translateX: 270,
    //           delay: function(el, i) { return i * 100; },
    //           direction: 'alternate',
    //           autoplay: true,
    //           easing: 'easeInOutSine'
    //         })
    //       })
    //     }
    //    // if(entries)
       
      
    // });

    // observer.observe(eli);
  }, []);

  return (
    <div>
      <div className="container-fluid process">
        
        <H1 data-aos="zoom-in" >Our Work Process</H1>


        <div className="row justify-content-center col-grid-2">
          {newData.map(({ id, ...other }) => (
            <div data-aos="zoom-in"  key={id} className="col-md-3 col-xs-12 col-sm-6 process-cols">
              <Card {...other} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Process;
