import humbertech from  '../../assets/services/tech.jpg'
import humbergame from  '../../assets/services/gamification.jpg'
import radicalhub from  '../../assets/services/radikalhub.jpg'
import humbervas from  '../../assets/services/humbervas.png'


const service_data = [
  {
      id:1,
    title: "Humber Technologies",
    subtitle: "We invest time and money into the design and development of out-of-the-box solutions as well as a wide range of services for businesses and end users.",
    imageUrl:humbertech,
    color:'#C70C0C',
    duration:'500',
    activity:[{text:'Blockchain Engineering', description: "We build public and private blockchain Networks for clients across various sectors; Banking, Government Agencies, Agriculture and Health."}, { text: 'Software Developement',  description: "We invest time and money into the design and development of out-of-the-box solutions as well as a wide range of services for businesses and end users."}]
  },
  {
    id:2,
    title: "Gaming Services",
    subtitle: "Developing and implementing Gaming-As-A-Service (GAAS) for clients all across the globe.",
    imageUrl:humbergame,
    color:' #3E4C57',
    duration:'2000',
    activity:[{text:'Gamification', url: "http://mobilottogames.com/tyl"}, {text:'Lottery', url: "http://www.mobilottogames.com/"}, {text: 'Consultation', url: "#"},]
    
  },
  {
    id:3,
    title: "Humber VAS",
    subtitle: "We implement & develop globally enabled Value Added Services on mobile, SMS, USSD as well as Digital Applications operating on telecom platforms.",
    imageUrl:humbervas,
    color:'#C70C0C',
    duration:'2000',
    activity:[{text: "Humber Vas", url: "https://humbervas.com/"}]
  },

  {
      id:4,
    title: "Business Incubation",
    subtitle: 
    "Helping startup companies to develop by providing services such as management training and office space through our hub and co-working arm, RadikalHUB.",
    imageUrl:radicalhub,
    color:'#3E4C57',
    duration:'2000',
    activity:[{text: 'RadikalHub', url: "radikalhub.com"}, ]
  },
];

export default service_data;
