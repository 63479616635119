import React, {useEffect} from "react";

import { Container } from "react-bootstrap";
import "./herosection.styles.css";
import bg_mobile from "../../assets/bg-mobile2.png";
import anime from 'animejs'
import humber from '../../assets/Humber2-1.gif'
import LogoSpinner from '../logoSpinner/logoSpinner.component'
import LogoSpinnerMd  from '../logoSpinner/logoSpinnerMobile.component'

const Hero = ({ background, boximage }) => {


  useEffect(()=>{ 

  
  }, [])
  return (
    <section className="hero" id="home">
      <div
        className="jumbotron jumbotron-fluid  mb-0 jumbo-bg"
        style={{
          backgroundImage: `url(${bg_mobile})`,
        }}
      >
      
        <Container>
          <div className="d-flex justify-content-between">
            <div className="row mt-3 mt-5">
              <div className="col-md-6 col-xs-12 col-sm-12 text-animate ">
                <h1 className="  text-justify text-center-md">
                  ...translating 
                  
                </h1>
                <h1 className="text-center-md">
                innovation to solution
                </h1>
                <p className="text-left ">
                  HumberOne (Humber International Limited) is a boutique company
                  specializing in a diverse range of business disciplines. Our
                  services and operations include: Lottery and Gamification
                  solutions, Value-added Services (VAS), Software Development
                  and RadikalHub ( a co-workspace ).
                </p>
              </div>
              <div className="col-md-6 col-xs-12 col-sm-12 box_logo_d">
                 {/* <img src={`${boximage}`} alt="boximage" />  */}
                 <LogoSpinner/>
               

              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="col-md-6 col-xs-12 col-sm-12 box_logo_md">
        
        <LogoSpinnerMd/>
      </div>
      
    </section>
  );
};

export default Hero;
