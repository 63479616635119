import Corevalues from  '../../assets/chooseus/corevalues.jpg'
import Commitment from  '../../assets/chooseus/commitment.jpg'
import Innovation from  '../../assets/chooseus/innovation.jpg'


export const ChooseUseData =  [
    {
        id: 1,
        title: "Innovation",
        description: "A great idea, executed brilliantly, and communicated in a way that is both intuitive and fully celebrates the magic of the initial concept",   
        image: Innovation

    },
    {
        id: 2,
        title: "Commitment to Quality",
        description: "We take the proper steps to ensure the highest quality possible the first time.",   
        image: Commitment

    },
    {
        id: 3,
        title: "Core Vaues",
        description: "Creative thinking, Timely Response, Candid & Straightforward, Objective Advice.",   
        image: Corevalues

    }
]

