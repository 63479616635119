import React from "react";




const CustomCard = ({ image, title }) => {
  return (
    <div
      className="card  process process-mobile"
      style={{
        border: "none",
      }}
    >
      <img src={`${image}`} alt={title} />
      <div className="card-footer">
        <h3 style={{
          fontFamily:'Br Firma, sans-seriff',
          fontWeight:'400'
        }} className="text-center">{title}</h3>
      </div>
    </div>
  );
};

export default CustomCard;
