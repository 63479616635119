import image1 from "../../assets/clientsLogo/1.png";
import image2 from "../../assets/clientsLogo/2.png";
import image3 from "../../assets/clientsLogo/3.png";
import image4 from "../../assets/clientsLogo/4.png";
import image5 from "../../assets/clientsLogo/5.png";
import image6 from "../../assets/clientsLogo/6.png";
import image7 from "../../assets/clientsLogo/7.png";
import image8 from "../../assets/clientsLogo/8.png";
import image9 from "../../assets/clientsLogo/9.png";
import image10 from "../../assets/clientsLogo/10.png";

const CLIENT_IMAGE = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image6,
  },
  {
    image: image7,
  },
  {
    image: image8,
  },
  {
    image: image9,
  },
  {
    image: image10,
  },
];

export default CLIENT_IMAGE;
